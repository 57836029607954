import { createSelector } from 'reselect';

import Config from 'Config';

import * as ImagesClient from 'Clients/Images';

import {
  ImageSettings,
  ViRSettings,
  ViRRenderItem
} from 'Types/Designs';

import { imageUrlSelector, getTopDownUrl } from './Urls';
import paramsSelector from './Params';

const HARDWOOD_RENDER_ITEM: ViRRenderItem = {
  surface: 'Floor',
  textureUrl: ImagesClient.Urls.Install.hardwood({ pixels: '20ppi' }),
  ppi: 20,
  repeatX: true,
  repeatY: true,
  rotate: 0
};

const virSettingsSelector = createSelector(
  paramsSelector,
  (params): ViRSettings => {
    if (params.viewMode !== '3d' || !params.colorway || !params.room) {
      return null;
    } else if (!params.install || params.install.installId !== 1) {
      return null;
    } else if (!params.colorway) {
      return null;
    }

    const ppi = getTexturePPI(params.colorway.repeatSize);
    const textureUrl = getTopDownUrl({
      params,
      square: false,
      normalMap: false,
      pixels: `${ppi}ppi`
    });
    let normalUrl = getTopDownUrl({
      params,
      square: false,
      normalMap: true,
      pixels: `${ppi}ppi`
    });

    if (params.customRug && params.customRugVisible) {
      normalUrl = undefined;
    }

    const materialPropertiesUrl = ImagesClient.Urls.MaterialProperties.colorwayMaterialProperties(params.colorway.designCode, params.colorway.colorCode);

    const items: ViRRenderItem[] = [];

    if (params.sectionIds.some(x => x === 6)) {
      items.push({ ...HARDWOOD_RENDER_ITEM },
        {
          surface: 'Corridor',
          textureUrl,
          normalUrl,
          ppi,
          repeatX: false,
          repeatY: true,
          rotate: 0,
          materialPropertiesUrl
        });
    } else if (params.sectionIds.some(x => x === 7) || (params.customRug && params.customRugVisible)) {
      items.push({ ...HARDWOOD_RENDER_ITEM },
        {
          surface: 'Rug',
          textureUrl,
          normalUrl,
          ppi,
          repeatX: false,
          repeatY: false,
          rotate: params.rotation,
          materialPropertiesUrl
        });
    } else {
      items.push({
        surface: 'Floor',
        textureUrl,
        normalUrl,
        ppi,
        repeatX: true,
        repeatY: true,
        rotate: params.rotation,
        materialPropertiesUrl
      });
    }

    return {
      roomId: params.room.roomId,
      items
    };
  }
);

const imageSettingsSelector = createSelector(
  paramsSelector,
  imageUrlSelector,
  virSettingsSelector,
  (params, imageUrl, virSettings): ImageSettings => {
    return {
      url: imageUrl,
      disableZoom: params.viewMode === '3d',
      disableContextMenu: Config.environment !== 'development',
      virSettings
    };
  }
);

export default imageSettingsSelector;

function getTexturePPI(repeatSize: TrykApi.Catalog.ITileSize): number {
  const maxPixelSize = 3750;
  const maxSizeInches = Math.max(repeatSize.widthInches, repeatSize.heightInches);

  let ppi = 72;

  if (maxSizeInches * ppi > maxPixelSize) {
    ppi = Math.floor(maxPixelSize / maxSizeInches);
  }

  return ppi;
}
